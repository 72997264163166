/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
// import { useLocation } from 'react-router'
import { toAbsoluteUrl } from "../../../../_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../app/modules/UserProfile/_redux/settingsActions";
// import { checkIsActive } from '../../../../_helpers'

export function HeaderMenu({ layoutProps }) {
  const [url, setUrl] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    // server call for getting category by id
    dispatch(actions.fetchSetting());
  }, []);

  const {
    currentState: { settingForEdit }
  } = useSelector(state => ({ currentState: state.settings }), shallowEqual);

  useEffect(() => {
    if (settingForEdit) setUrl(settingForEdit.userName);
  }, [settingForEdit]);

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-rel menu-item-active`}>
          <a
            className="menu-link"
            href={`https://marketplace.blackforce.ca/store`}
            target="_blank"
            rel="noreferrer"
          >
            <span className="menu-text mr-2">Store link </span>
            <span className="svg-icon svg-icon-primary svg-icon-1x">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Share.svg")}
              ></SVG>
            </span>
            {/* {layoutProps.rootArrowEnabled && <i className="menu-arrow" />} */}
          </a>
        </li>
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
