import * as requestFromServer from "./settingsCrud";
import { settingsSlice, callTypes } from "./settingsSlice";

const { actions } = settingsSlice;

export const fetchSettings = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSetting(queryParams)
    .then(response => {
      if (response.data.status) {
        const { totalCount, entities } = response.data.data;
        dispatch(actions.settingsFetched({ totalCount, entities }));
      } else {
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find settings";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSetting = () => dispatch => {
  // if (!id) {
  //   return dispatch(actions.settingFetched({ settingForEdit: undefined }));
  // }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSettingById()
    .then(response => {
      const setting = response.data.data;
      dispatch(actions.settingFetched({ settingForEdit: setting }));
    })
    .catch(error => {
      error.clientMessage = "Can't find setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSetting = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSetting(id)
    .then(response => {
      dispatch(actions.settingDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSetting = settingForCreation => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.createSetting(settingForCreation);
    if (response.data.status) {
      const setting = response.data.setting;
      dispatch(actions.settingCreated({ setting }));
    }
    return response;
  } catch (error) {
    console.log(error);
    error.clientMessage = "Can't create setting";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const updateSetting = setting => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.updateSetting(setting);
    if (response.data.status) {
      // dispatch(actions.settingUpdated({ setting }));
      dispatch(actions.settingFetched({ settingForEdit: setting }));
    }
    return response;
  } catch (error) {
    error.clientMessage = "Can't update setting";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

// export const updateSettingStatus = (ids, status) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForSetting(ids, status)
//     .then(() => {
//       dispatch(actions.settingStatusUpdated({ ids, status }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't update setting status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const deleteSettings = ids => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.deleteSettings(ids);
    if (response.data.status) {
      dispatch(actions.settingsDeleted({ ids }));
    }
    return response;
  } catch (error) {
    error.clientMessage = "Can't delete settings";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const updateSettingStatus = (id, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForSetting(id, status)
    .then(() => {
      dispatch(actions.settingStatusUpdated({ id, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update setting status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDashboard = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchDashboard()
    .then(response => {
      const dashboard = response.data.data;
      dispatch(actions.dashboardFetched({ dashboard }));
    })
    .catch(error => {
      error.clientMessage = "Can't find setting";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
// fetchDashboard
