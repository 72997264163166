/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";
import SVG from "react-inlinesvg";

export function AsideMenuList({ layoutProps }) {
  const { role_level } = useSelector(
    state => state.auth.user.role_details,
    shallowEqual
  );
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* begin::section */}
        {/* <li className='menu-section '>
					<h4 className='menu-text'>Application</h4>
					<i className='menu-icon flaticon-more-v2'></i>
				</li> */}
        {/* end:: section */}

        <>
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/*Products */}
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/products",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/products">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
              </span>
              <span className="menu-text">Products</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Products</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/products")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/products">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">All Products</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "product-categories"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="product-categories">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Product Categories</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                {/* <li
                  className={`menu-item ${getMenuItemActive(
                    "product-categories"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="product-categories">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Subscribers</span>
                  </NavLink>
                </li> */}
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>
          {/*end::1 Level*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/sales",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/sales">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                />
              </span>
              <span className="menu-text">Sales</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Sales</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/sales")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/sales">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">All Sales</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                {/* <li
                  className={`menu-item ${getMenuItemActive("sales-pages")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="sales-pages">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Sales pages</span>
                  </NavLink>
                </li> */}
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("customers")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="customers">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Customers</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>

          {/* <li
            className={`menu-item ${getMenuItemActive("/affiliates", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/affiliates">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")}
                />
              </span>
              <span className="menu-text">Affiliates</span>
            </NavLink>
          </li> */}
          {/* <li
            className={`menu-item ${getMenuItemActive("/coupon", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/coupon">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")} />
              </span>
              <span className="menu-text">Coupons</span>
            </NavLink>
          </li> */}
          {role_level < 4 && (
            <li
              className={`menu-item ${getMenuItemActive("/settings", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/settings">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")}
                  />
                </span>
                <span className="menu-text">Settings</span>
              </NavLink>
            </li>
          )}
        </>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
