import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import { DashboardPage } from "../app/pages/DashboardPage";
const ProductsPage = lazy(() => import("./modules/Profiles/profilePage"));
const SalesPage = lazy(() => import("./modules/Sales/salePage"));
const CustomerPage = lazy(() => import("./modules/Customers/customerPage"));
const GroupsPage = lazy(() => import("./modules/Groups/groupPage"));
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/product-categories" component={GroupsPage} />
        <Route path="/products" component={ProductsPage} />
        <Route path="/sales" component={SalesPage} />
        <Route path="/customers" component={CustomerPage} />
        <Route path="/settings" component={UserProfilepage} />
        {/* <Redirect to="/error" /> */}
      </Switch>
    </Suspense>
  );
}
