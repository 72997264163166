import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export default function StatsCard({ dashboard }) {
  return (
    <>
      <div className="row">
        <div className="col-xl-4">
          {/*begin::Stats Widget 29*/}
          <div
            className="card card-custom bg-light-success card-stretch gutter-b"
            // style={{
            //   backgroundPosition: "right top",
            //   backgroundSize: "30% auto",
            //   backgroundImage: `url(/media/svg/shapes/abstract-1.svg)`
            // }}
          >
            {/*begin::Body*/}
            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-success">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")}
                />
                {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-opened.svg*/}
                {/*end::Svg Icon*/}
              </span>
              <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                {dashboard?.products || 0}
              </span>
              <span className="font-weight-bold text-muted font-size-sm">
                Products
              </span>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Stats Widget 29*/}
        </div>
        <div className="col-xl-4">
          {/*begin::Stats Widget 30*/}
          <div className="card card-custom bg-light-danger card-stretch gutter-b">
            {/*begin::Body*/}
            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group.svg"
                  )}
                />
                {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}

                {/*end::Svg Icon*/}
              </span>
              <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                {dashboard?.customers || 0}
              </span>
              <span className="font-weight-bold text-muted font-size-sm">
                Customers
              </span>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Stats Widget 30*/}
        </div>
        <div className="col-xl-4">
          {/*begin::Stats Widget 31*/}
          <div className="card card-custom bg-light-info card-stretch gutter-b">
            {/*begin::Body*/}
            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-info">
                {/*begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg*/}
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                />

                {/*end::Svg Icon*/}
              </span>
              <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                ${dashboard?.sales || 0}
              </span>
              <span className="font-weight-bold text-muted font-size-sm">
                Total Sales
              </span>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Stats Widget 31*/}
        </div>
        {/* <div className="col-xl-3">

          <div className="card card-custom bg-light-warning card-stretch gutter-b">

            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-warning">

                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Contact1.svg"
                  )}
                />

              </span>
              <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 text-hover-primary d-block">
                16
              </span>
              <span className="font-weight-bold text-muted font-size-sm">
                Merchants
              </span>
            </div>

          </div>

        </div> */}
      </div>
    </>
  );
}
