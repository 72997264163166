import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Demo1Dashboard } from "./Demo1Dashboard";
import * as actions from "./_redux/dashboardActions";

export function Dashboard() {
  const { user, dashboard, isLoading } = useSelector(
    ({ auth, dashboard }) => ({
      user: auth.user,
      dashboard: dashboard.dashboardForEdit,
      isLoading: dashboard.actionsLoading
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getDashboard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {user && !isLoading && (
        <Demo1Dashboard user={user} dashboard={dashboard} />
      )}
    </>
  );
}
