import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	carts: [],
	lastError: null,
};
export const callTypes = {
	list: "list",
	action: "action",
};

function getProductQuantity(id, carts) {
	const quantity = carts.find((product) => product.id === id)?.quantity;
	if (quantity === undefined) {
		return 0;
	}

	return quantity;
}

export const cartsSlice = createSlice({
	name: "carts",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false;
			} else {
				state.actionsLoading = false;
			}
		},
		startCall: (state, action) => {
			state.error = null;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true;
			} else {
				state.actionsLoading = true;
			}
		},
		// getProductById
		setCartProducts: (state, action) => {
			state.actionsLoading = false;
			state.products = action.payload.products;
			state.error = null;
		},
		addOneToCart: (state, action) => {
			const { id, name, image, price, maxQuantity } = action.payload;
			const quantity = getProductQuantity(id, state.carts);
			if (quantity === 0) {
				state.carts = [
					...state.carts,
					{
						id,
						name,
						image,
						price,
						maxQuantity,
						quantity: 1,
					},
				];
			} else {
				state.carts = [
					...state.carts.map((product) =>
						product.id === id
							? {
									...product,
									quantity:
										product.maxQuantity === 0
											? product.quantity + 1
											: product.quantity < product.maxQuantity
											? product.quantity + 1
											: product.maxQuantity,
							  }
							: product
					),
				];
			}
		},
		deleteFromCart: (state, action) => {
			const { id } = action.payload;
			state.carts = state.carts.filter((item) => item.id !== id);
		},
		// findProducts
		incrementQuantity: (state, action) => {
			const { id, category, quantity } = action.payload;
			state.carts = state.carts.map((item) => {
				if (item.id === id && item.category === category) {
					return { ...item, quantity };
				}
				return item;
			});
			state.actionsLoading = false;
			state.error = null;
		},
		removeOneFromCart: (state, action) => {
			const { id } = action.payload;
			const quantity = getProductQuantity(id, state.carts);
			if (quantity === 1) {
				state.carts = state.carts.filter((item) => item.id !== id);
			} else {
				state.carts = [
					...state.carts.map((product) =>
						product.id === id
							? { ...product, quantity: product.quantity - 1 }
							: product
					),
				];
			}
		},
	},
});
