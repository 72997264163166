import axios from "axios";

export const SETTING_URL = process.env.REACT_APP_API_URL + "/settings";

// CREATE =>  POST: add a new setting to the server
export function createSetting(setting) {
  return axios.post(SETTING_URL, { setting });
}

// READ
export function getAllSetting() {
  return axios.get(SETTING_URL);
}

export function getSettingById() {
  return axios.get(`${SETTING_URL}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSetting(queryParams) {
  return axios.get(`${SETTING_URL}?query=${JSON.stringify(queryParams)}`);
}

// UPDATE => PUT: update the procuct on the server
export function updateSetting(setting) {
  return axios.put(`${SETTING_URL}/${setting.id}`, { setting });
}

// UPDATE Status
export function updateStatusForSetting(id, status) {
  return axios.post(`${SETTING_URL}/status`, {
    id,
    status
  });
}

// DELETE => delete the setting from the server
export function deleteSetting(settingId) {
  return axios.delete(`${SETTING_URL}/${settingId}`);
}

// DELETE Setting by ids
export function deleteSettings(ids) {
  return axios.post(`${SETTING_URL}/deleteMultiple`, { settings: ids });
}

export function getManagers() {
  return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/managers`);
}

// export function fetchDashboard() {
//   return axios.get("http://localhost:3001/dashboard");
// }

export function fetchDashboard() {
  return axios.get(process.env.REACT_APP_API_ENDPOINT + "/dashboard");
}
