/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_helpers";
import { navigateToUrl } from "single-spa";

export function QuickUser() {
  const user = useSelector(state => state.auth.user, shallowEqual);
  const modules = useSelector(
    state => state.auth.microsolution?.modules,
    shallowEqual
  );
  const setNewModuleUrl = module => {
    const splashScreen = document.getElementById("splash-screen");
    if (splashScreen) splashScreen.classList.remove("hidden");
    navigateToUrl(`/${module.identifier}`);
  };

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">User Profile</h3>
        <a
          className="btn btn-xs btn-icon btn-light btn-hover-primary cursor-pointer"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${user.user_detail.image_url ||
                  toAbsoluteUrl("/media/users/blank.png")})`
              }}
            />
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <span className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
              {user.user_detail.name}
            </span>
            <div className="text-muted mt-1">
              <b>Role: </b>
              {user.role_details.role_name}
            </div>
            <div className="navi mt-2">
              <a
                href={`mailto:${user.user_detail.email}`}
                className="navi-item cursor-pointer"
              >
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user.user_detail.email.length > 20
                      ? user.user_detail.email.substring(0, 20) + "..."
                      : user.user_detail.email}
                  </span>
                </span>
              </a>
            </div>
            <a href="/logout" className="btn btn-light-primary btn-bold">
              Sign Out
            </a>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />
        <div className="text-muted mt-1">
          <b>Business Name: </b>
          {user.current_business}
        </div>
        <div className="text-muted mt-1">
          <b>Department Name: </b>
          {user.department_name}
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div className="navi navi-spacer-x-0 p-0">
          {modules &&
            modules.map((module, index) => (
              <button
                to="/user-profile"
                className="navi-item"
                key={index}
                onClick={() => setNewModuleUrl(module)}
                style={{
                  border: "none",
                  background: "none",
                  outline: "inherit",
                  textAlign: "left"
                }}
              >
                <div className="navi-link">
                  <div className="symbol symbol-40 bg-light mr-3">
                    <div className="symbol-label">
                      <span className="svg-icon svg-icon-md svg-icon-success">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Layout/Layout-4-blocks.svg"
                          )}
                        ></SVG>
                      </span>
                    </div>
                  </div>
                  <div className="navi-text">
                    <div className="font-weight-bold text-capitalize">
                      {module.name}
                    </div>
                    {/* <div className='text-muted'>
											{module.url.length > 30
												? module.url.substring(0, 30) + '...'
												: module.url}
										</div> */}
                  </div>
                </div>
              </button>
            ))}
        </div>
      </div>
    </div>
  );
}
