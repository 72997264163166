import ApexCharts from "apexcharts";
import { KTUtil } from "../../../../_assets/js/components/util";

export function _initMixedWidget17() {
  var element = document.getElementById("kt_mixed_widget_17_chart");
  var color = KTUtil.hasAttr(element, "data-color")
    ? KTUtil.attr(element, "data-color")
    : "warning";
  var height = parseInt(KTUtil.css(element, "height"));

  if (!element) {
    return;
  }

  var options = {
    series: [
      {
        name: "Net Profit",
        data: [30, 25, 45, 30, 55, 55]
      }
    ],
    chart: {
      type: "area",
      height: height,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 1
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [KTAppSettings["colors"]["theme"]["base"][color]]
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: KTAppSettings["colors"]["gray"]["gray-500"],
          fontSize: "12px",
          fontFamily: KTAppSettings["font-family"]
        }
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: KTAppSettings["colors"]["gray"]["gray-300"],
          width: 1,
          dashArray: 3
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: KTAppSettings["font-family"]
        }
      }
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: {
        show: false,
        style: {
          colors: KTAppSettings["colors"]["gray"]["gray-500"],
          fontSize: "12px",
          fontFamily: KTAppSettings["font-family"]
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: KTAppSettings["font-family"]
      },
      y: {
        formatter: function(val) {
          return "$" + val + " thousands";
        }
      }
    },
    colors: [KTAppSettings["colors"]["theme"]["light"][color]],
    markers: {
      colors: [KTAppSettings["colors"]["theme"]["light"][color]],
      strokeColor: [KTAppSettings["colors"]["theme"]["base"][color]],
      strokeWidth: 3
    }
  };

  var chart = new ApexCharts(element, options);
  chart.render();
}

export function _initChartsWidget3(dashboard) {
  let monthlySales = dashboard?.monthlySales;
  // data: [30, 40, 40, 90, 90, 70, 70, 20, 23, 50, 11]
  // console.log(Object.keys(monthlySales));
  // console.log(Object.values(monthlySales));
  var element = document.getElementById("kt_charts_widget_3_chart");

  if (!element) {
    return;
  }

  var options = {
    series: [
      {
        name: "Total Sales",
        data: Object.values(monthlySales)
      }
    ],
    chart: {
      type: "area",
      height: 350,
      toolbar: {
        show: false
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 1
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [KTAppSettings["colors"]["theme"]["base"]["info"]]
    },
    xaxis: {
      categories: Object.keys(monthlySales),
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: KTAppSettings["colors"]["gray"]["gray-500"],
          fontSize: "12px",
          fontFamily: KTAppSettings["font-family"]
        }
      },
      crosshairs: {
        position: "front",
        stroke: {
          color: KTAppSettings["colors"]["theme"]["base"]["info"],
          width: 1,
          dashArray: 3
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: KTAppSettings["font-family"]
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: KTAppSettings["colors"]["gray"]["gray-500"],
          fontSize: "12px",
          fontFamily: KTAppSettings["font-family"]
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: KTAppSettings["font-family"]
      },
      y: {
        formatter: function(val) {
          return "$" + val;
        }
      }
    },
    colors: [KTAppSettings["colors"]["theme"]["light"]["info"]],
    grid: {
      borderColor: KTAppSettings["colors"]["gray"]["gray-200"],
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    markers: {
      //size: 5,
      //colors: [KTAppSettings['colors']['theme']['light']['danger']],
      strokeColor: KTAppSettings["colors"]["theme"]["base"]["info"],
      strokeWidth: 3
    }
  };

  var chart = new ApexCharts(element, options);
  chart.render();
}

var KTAppSettings = {
  breakpoints: { sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400 },
  colors: {
    theme: {
      base: {
        white: "#ffffff",
        primary: "#3699FF",
        secondary: "#E5EAEE",
        success: "#1BC5BD",
        info: "#8950FC",
        warning: "#FFA800",
        danger: "#F64E60",
        light: "#E4E6EF",
        dark: "#181C32"
      },
      light: {
        white: "#ffffff",
        primary: "#E1F0FF",
        secondary: "#EBEDF3",
        success: "#C9F7F5",
        info: "#EEE5FF",
        warning: "#FFF4DE",
        danger: "#FFE2E5",
        light: "#F3F6F9",
        dark: "#D6D6E0"
      },
      inverse: {
        white: "#ffffff",
        primary: "#ffffff",
        secondary: "#3F4254",
        success: "#ffffff",
        info: "#ffffff",
        warning: "#ffffff",
        danger: "#ffffff",
        light: "#464E5F",
        dark: "#ffffff"
      }
    },
    gray: {
      "gray-100": "#F3F6F9",
      "gray-200": "#EBEDF3",
      "gray-300": "#E4E6EF",
      "gray-400": "#D1D3E0",
      "gray-500": "#B5B5C3",
      "gray-600": "#7E8299",
      "gray-700": "#5E6278",
      "gray-800": "#3F4254",
      "gray-900": "#181C32"
    }
  },
  "font-family": "Poppins"
};
