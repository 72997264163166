import { createSlice } from "@reduxjs/toolkit";

const initialStoretate = {
  listLoading: false,
  actionsLoading: false,
  productCount: 0,
  totalCount: 0,
  entities: [],
  carts: [],
  storeForEdit: undefined,
  productForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const storeSlice = createSlice({
  name: "store",
  initialState: initialStoretate,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    storeFetched: (state, action) => {
      state.listLoading = false;
      state.storeForEdit = action.payload.storeForEdit;
      state.entities = action.payload.storeForEdit.products;
      state.productCount = action.payload.storeForEdit.productCount;
      state.productForEdit = undefined;
      state.error = null;
    },
    storeFetched_M: (state, action) => {
      state.actionsLoading = false;
      // state.storeForEdit = action.payload.storeForEdit;
      state.productCount = action.payload.storeForEdit.productCount;
      state.entities = action.payload.storeForEdit.products;
      state.productForEdit = undefined;
      state.error = null;
    },
    fetchOneStoreProoduct: (state, action) => {
      const { productId } = action.payload;
      state.productForEdit = state.entities.find(
        product => product.id === productId
      );
    },
    addToCart: (state, action) => {
      const { id, category } = action.payload;
      const cartItemExistIndex = state.carts.findIndex(
        product => product.id === id && product.category === category
      );
      if (cartItemExistIndex > -1) {
        const cartItem = state.carts[cartItemExistIndex];
        cartItem.quantity = cartItem.quantity += 1;
        cartItem.isAdded = true;
        state.carts[cartItemExistIndex] = cartItem;
        state.carts = [...state.carts];
        state.actionsLoading = false;
        state.error = null;
      } else {
        const filteredProduct = state.products.filter(
          product => product.id === id && product.category === category
        );
        console.log(filteredProduct);
        filteredProduct[0].quantity = 1;
        filteredProduct[0].isAdded = true;
        const cartItem = filteredProduct[0];

        state.carts = [...state.carts, cartItem];
        state.actionsLoading = false;
        state.error = null;
      }
    },
    removeFromCart: (state, action) => {
      const { id, category } = action.payload;
      state.carts = state.carts.filter(
        item => item.id !== id && item.category !== category
      );
      state.actionsLoading = false;
      state.error = null;
    },
    // findProducts
    incrementQuantity: (state, action) => {
      const { id, category, quantity } = action.payload;
      console.log(quantity);
      state.carts = state.carts.map(item => {
        if (item.id === id && item.category === category) {
          return { ...item, quantity };
        }
        return item;
      });
      state.actionsLoading = false;
      state.error = null;
    },
    decrementQuantity: (state, action) => {
      const { id, category, quantity } = action.payload;
      console.log(quantity);
      const decrement_quantity = quantity > 0 ? quantity : 0;
      state.carts = state.carts.map(item => {
        if (item.id === id && item.category === category) {
          return { ...item, quantity: decrement_quantity };
        }
        return item;
      });
      state.actionsLoading = false;
      state.error = null;
    }
  }
});
