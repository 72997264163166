import axios from "axios";

export const DASHBOARD_URL = process.env.REACT_APP_API_URL + "/dashboards";

// READ
export function getDashboard(id) {
  return axios.get(`${DASHBOARD_URL}`);
}

// UPDATE => PUT: update the procuct on the server
export function createDashboard(dashboard) {
  return axios.post(`${DASHBOARD_URL}`, { dashboard });
}
