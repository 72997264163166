import * as requestFromServer from "./dashboardCrud";
import { dashboardSlice, callTypes } from "./dashboardSlice";

const { actions } = dashboardSlice;

export const getDashboard = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDashboard()
    .then(response => {
      if (response.data.status) {
        const dashboard = response.data.data;
        dispatch(
          actions.dashboardFetched({
            dashboard
          })
        );
      } else {
        dispatch(
          actions.dashboardFetched({
            dashboard: undefined
          })
        );
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find dashboard";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createDashboard = dashboard => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.createDashboard(dashboard);
    if (response.data.status) {
      dispatch(getDashboard(response.data.data.createdBy));
      return response;
    }
  } catch (error) {
    error.clientMessage = "Can't create dashboard";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};
