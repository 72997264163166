import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { dashboardSlice } from "../_metronic/_partials/dashboards/_redux/dashboardSlice";
import { productsSlice } from "../app/modules/Profiles/_redux/productSlice";
import { categoriesSlice } from "../app/modules/Groups/_redux/categorySlice";
import { settingsSlice } from "../app/modules/UserProfile/_redux/settingsSlice";
import { storeSlice } from "../app/store/_redux/storeSlice";
import { cartsSlice } from "../app/store/_redux/carts/cartsSlice";
import { salesSlice } from "../app/modules/Sales/_redux/saleSlice";
import { customerSlice } from "../app/modules/Customers/_redux/customerSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  dashboard: dashboardSlice.reducer,
  products: productsSlice.reducer,
  category: categoriesSlice.reducer,
  settings: settingsSlice.reducer,
  store: storeSlice.reducer,
  cart: cartsSlice.reducer,
  sales: salesSlice.reducer,
  customers: customerSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
