import React from "react";
import { Link } from "react-router-dom";
import StatsCard from "./components/StatsCard";
import MonthlySales from "./components/MonthlySales";

export function Demo1Dashboard({ user, dashboard }) {
  return (
    <>
      <div>
        <div className="row mb-5 d-flex justify-content-end">
          <div className="mr-10">
            <Link
              to="/sales"
              className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-secondary mr-3 cursor-pointer"
            >
              Manage Sales
            </Link>
            <Link
              to="/products/new"
              className="btn btn-sm fw-bold btn-primary cursor-pointer"
            >
              Add Product
            </Link>
          </div>
        </div>

        <StatsCard dashboard={dashboard} />

        <div className="row">
          {/* <div className="col-12 col-md-4 col-lg-4 col-xl-4">
            <WeeklyStats />
          </div> */}
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            {dashboard && <MonthlySales dashboard={dashboard} />}
          </div>
        </div>
      </div>
    </>
  );
}
