import React, { useEffect } from "react";
import { _initChartsWidget3 } from "./utils/ChartInit";

export default function MonthlySales({ dashboard }) {
  useEffect(() => {
    _initChartsWidget3(dashboard);
  }, [dashboard]);
  return (
    <>
      <div className="card card-custom card-stretch gutter-b">
        {/*begin::Header*/}
        <div className="card-header h-auto border-0">
          <div className="card-title py-5">
            <h3 className="card-label">
              <span className="d-block text-dark font-weight-bolder">
                Sales This Months
              </span>
              <span className="d-block text-muted mt-2 font-size-sm">
                {/* More than 500+ new orders */}
              </span>
            </h3>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body">
          <div id="kt_charts_widget_3_chart"></div>
        </div>
        {/*end::Body*/}
      </div>
    </>
  );
}
